.fc-toolbar .fc-button {
    background-color: #409d8e; /* Change this to your desired color */
    color: #ffffff; /* Change this to the text color you prefer */
    border-color: #409d8e; /* Change this to match the background color */
    /* Add other styling properties as needed */
  }
  
  /* Customize the hovered state of toolbar buttons */
  .fc-toolbar .fc-button:hover {
    background-color: #398d7f; /* Change this to the hovered color */
    color: #ffffff; /* Change this to the text color you prefer */
    border-color: #398d7f; /* Change this to match the hovered background color */
    /* Add other styling properties as needed */
  }
  .event-custom-color {
    background-color: #409d8e; /* Set the desired color for events */
    border-color: #409d8e; /* Set the border color if needed */
    color: white; /* Set the text color */
  }

  .fc-button-active {
    background-color: #398d7f !important; /* Set the desired background color */
    color: white !important; /* Set the text color */
    border-color: #398d7f !important; /* Set the border color */
    /* Add other styling properties as needed */
  }

 /* Example CSS */
/* .day-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
} */

/* .day-cell.highlighted {
  background-color: yellow;
} */

/* .day-cell.event-date {
  background-color: rgb(215, 239, 247);
} */
.fc-day-highlighted {
  background-color: yellow;
  /* Add other styling here */
}